import { useDeposit } from "../../contexts/DepositContext";
import { useWindowDimensions } from "../AppBar";
import * as Dialog from "@radix-ui/react-dialog";
import { DialogContent, DialogIconButton, DialogOverlay } from "../Dialog";
import { MORTAR_MINT_URL } from "../../utils/ids";
import { ContrastButton } from "../buttons/GradientButton";
import { CrossIcon } from "../Icons";
import React from "react";

const DepositModal = ({ title }: { title: string }) => {
  const { isModalVisible, setIsModalVisible } = useDeposit();
  const { width } = useWindowDimensions();

  return (
    <>
      <Dialog.Root open={isModalVisible} onOpenChange={setIsModalVisible}>
        <Dialog.Portal>
          <DialogOverlay />
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              height: "80%",
              justifyContent: "center",
              backgroundColor: "#1C1B1F",
              padding: width <= 768 ? "0px 20px" : "0px 96px",
            }}
          >
            <div
              style={
                width <= 768
                  ? { fontSize: 32, lineHeight: "44px", fontWeight: 500 }
                  : { fontSize: 60, fontWeight: 500 }
              }
            >
              {title}
            </div>

            <div style={{ height: 20 }} />

            <div
              style={
                width <= 768
                  ? { fontSize: 16, lineHeight: "20px", textAlign: "center" }
                  : { fontSize: 20, textAlign: "center" }
              }
            >
              You&apos;`re on the waitlist.
              <div style={{ height: 20 }} />
              <a href={MORTAR_MINT_URL}>
                <ContrastButton>Mint your Saga Pass</ContrastButton>
              </a>
            </div>
            <Dialog.Close asChild>
              <DialogIconButton
                onClick={() => setIsModalVisible(false)}
                style={{ padding: 10 }}
              >
                <CrossIcon size={25} />
              </DialogIconButton>
            </Dialog.Close>
          </DialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DepositModal;
