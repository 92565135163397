import React from "react";

import { ITransactionData } from "../../contexts/PreorderContext";
import { MORTAR_MINT_AMOUNT } from "../../utils/ids";

import { shortenAddress } from "../../utils";
import { useWindowDimensions } from "../AppBar";

import { StyledSeparator } from "../shared/StylingComponents";
import NoRefund from "./NoRefund";

const TransactionItem = ({
  transaction,
}: {
  transaction: ITransactionData;
}) => {
  const { width } = useWindowDimensions();
  const flexDirection = width <= 768 ? "column" : "row";

  const { numSagaPhonesPreordered, transactionSignature, blockTime } =
    transaction;
  const shortTxnId = transactionSignature
    ? shortenAddress(transactionSignature)
    : "";
  const solScanUrl = `https://solscan.io/tx/${transactionSignature}`;
  const preorderDate = blockTime
    ? new Date(blockTime * 1000).toLocaleString()
    : "";

  return (
    <div style={{ width: "100%" }}>
      {!!preorderDate && (
        <div className="text-subtitle">Pre-ordered on: {preorderDate}</div>
      )}
      <div style={{ display: "flex", width: "100%", flexDirection }}>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 2 }}>
          <div>
            Transaction ID: <b>{shortTxnId}</b>
          </div>
          <a href={solScanUrl} rel="noopener noreferrer" target="_blank">
            View on Solscan
          </a>
        </div>

        <div className="text-subtitle" style={{ flexGrow: 1 }}>
          Ordered:{" "}
          <span style={{ color: "white", fontWeight: 600 }}>
            {numSagaPhonesPreordered}
          </span>
        </div>

        <div className="text-subtitle" style={{ flexGrow: 0 }}>
          {numSagaPhonesPreordered >= 0 ? "Total" : "Refund"} due:&nbsp;
          <span style={{ color: "white", fontWeight: 600 }}>
            {numSagaPhonesPreordered * MORTAR_MINT_AMOUNT} USDC
          </span>
        </div>
      </div>
      <div style={{ height: 10 }} />
      <StyledSeparator />
      <div style={{ height: 10 }} />
    </div>
  );
};

const TransactionList = ({
  hasPreorder,
  preorderTransactions,
}: {
  hasPreorder: boolean;
  preorderTransactions: ITransactionData[];
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <h3>Your pre-orders</h3>

      {hasPreorder && preorderTransactions.length ? (
        <div>
          {preorderTransactions.map((transaction: ITransactionData) => {
            return (
              <TransactionItem
                transaction={transaction}
                key={transaction.transactionSignature}
              />
            );
          })}
        </div>
      ) : (
        <NoRefund />
      )}
    </div>
  );
};

export default TransactionList;
