import React from "react";
import { useLocalStorageState } from "../utils";

export interface IDeveloperPreorderData {
  email?: string;
  developer?: string;
  githubUser?: string;
  twitterUser?: string;
  company?: string;
}

export interface IDeveloperPreorderSetters {
  setPreorderData: React.Dispatch<React.SetStateAction<IDeveloperPreorderData>>;
  setEmailStr: React.Dispatch<React.SetStateAction<string>>;
  setDeveloperStr: React.Dispatch<React.SetStateAction<string>>;
  setGithubUser: React.Dispatch<React.SetStateAction<string>>;
  setTwitterUser: React.Dispatch<React.SetStateAction<string>>;
  setCompanyStr: React.Dispatch<React.SetStateAction<string>>;
}

export enum DATA_TO_UPDATE {
  email = "email",
  developer = "developer",
  githubUser = "githubUser",
  twitterUser = "twitterUser",
  company = "company",
}

export const defaultPreorderData: IDeveloperPreorderData = {
  email: "",
  developer: "",
  githubUser: "",
  twitterUser: "",
  company: "",
};

function useLocalStoragePreorderData(): [
  IDeveloperPreorderData,
  IDeveloperPreorderSetters
];
function useLocalStoragePreorderData(
  key: string
): [string, React.Dispatch<React.SetStateAction<string>>];

function useLocalStoragePreorderData(key?: string) {
  const [preorderData, setPreorderData] =
    useLocalStorageState<IDeveloperPreorderData>(
      "preorderData",
      defaultPreorderData
    );

  const updatePreorderData = (data: string, dataToUpdate: DATA_TO_UPDATE) => {
    const newPreorderData = {
      ...preorderData,
      [dataToUpdate]: data,
    };
    setPreorderData(newPreorderData);
  };
  const setEmailStr = (emailStr: string) =>
    updatePreorderData(emailStr, DATA_TO_UPDATE.email);
  const setDeveloperStr = (developerStr: string) =>
    updatePreorderData(developerStr, DATA_TO_UPDATE.developer);
  const setGithubUser = (githubUserStr: string) =>
    updatePreorderData(githubUserStr, DATA_TO_UPDATE.githubUser);
  const setTwitterUser = (twitterUserStr: string) =>
    updatePreorderData(twitterUserStr, DATA_TO_UPDATE.twitterUser);
  const setCompanyStr = (companyStr: string) =>
    updatePreorderData(companyStr, DATA_TO_UPDATE.company);

  if (!key) {
    return [
      preorderData,
      {
        setPreorderData,
        setEmailStr,
        setDeveloperStr,
        setGithubUser,
        setTwitterUser,
        setCompanyStr,
      },
    ];
  }

  const {
    email = "",
    developer = "",
    githubUser = "",
    twitterUser = "",
    company = "",
  } = preorderData;

  switch (key) {
    case "email":
    case "mortar-email-str":
      return [email, setEmailStr];
    case "developer":
    case "mortar-developer-str":
      return [developer, setDeveloperStr];
    case "githubUser":
      return [githubUser, setGithubUser];
    case "twitterUser":
      return [twitterUser, setTwitterUser];
    case "company":
    case "mortar-company-str":
      return [company, setCompanyStr];
  }
}

export { useLocalStoragePreorderData };
