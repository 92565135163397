import React, { useContext, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { AccountInfo, PublicKey } from "@solana/web3.js";

import { useConnection } from "../ConnectionContext";
import {
  PromiseResource,
  cachedPromises,
  usePromiseResult,
  usePromiseResults,
} from "../../utils/suspense";

const AccountsContext = React.createContext<any>(null);

export const useAccountsContext = () => {
  const context = useContext(AccountsContext);

  return context;
};

export const subscribeAccount = (
  address: PublicKey | null,
  initialAccount: PromiseResource<AccountInfo<Buffer>> | null
) => {
  const connection = useConnection();

  const key = address.toBase58();

  const [account, setAccount] = React.useState(initialAccount);

  React.useEffect(() => {
    let subId = 0;
    const updateAccount = (account: AccountInfo<Buffer> | null) => {
      if (account) {
        cachedPromises[initialAccount.key] = {
          __type: "result",
          result: account,
        };
        setAccount(
          new PromiseResource<AccountInfo<Buffer>>(initialAccount.key, account)
        );
      }
    };

    (async () => {
      subId = connection.onAccountChange(address, updateAccount, "processed");
    })();

    return () => {
      if (subId) {
        connection.removeAccountChangeListener(subId);
      }
    };
  }, [connection, setAccount, address]);

  return { account, address };
};

export const useGetAccountInfo = (address: PublicKey) => {
  const connection = useConnection();
  return usePromiseResult(`INFO-${address.toBase58()}`, () =>
    connection.getAccountInfo(address, "processed")
  );
};

export const useSubscribeAccountInfo = (address: PublicKey) => {
  const initial = useGetAccountInfo(address);
  return subscribeAccount(address, initial);
};

export const useSubscribeMultipleAccounts = (
  key: string,
  addresses: Array<PublicKey>
) => {
  const connection = useConnection();
  const initialAccounts = usePromiseResults(key, () =>
    connection.getMultipleAccountsInfo(addresses, "processed")
  );

  const [accounts, setAccounts] = React.useState(initialAccounts);

  React.useEffect(() => {
    let subIds = [];
    const updateAccount = (
      account: AccountInfo<Buffer> | null,
      idx: number
    ) => {
      if (account) {
        const cacheEntry = cachedPromises[key];
        let nextAccounts;
        if (cacheEntry.__type === "result") {
          // ez case. already got the initial result back
          nextAccounts = cacheEntry.result.slice();
          nextAccounts.splice(idx, 1, account);
          cachedPromises[key] = { __type: "result", result: nextAccounts };
          setAccounts(new PromiseResource(key, nextAccounts));
        } else {
          // we are still waiting on the initial result but we don't want to
          // forget about these account updates (initial result will be stale
          // for those accounts).
          nextAccounts =
            cacheEntry.__type === "buffered"
              ? cacheEntry.intermediate.slice()
              : Array.from({ length: addresses.length }, () => null);
          nextAccounts.splice(idx, 1, account);
          cachedPromises[key] = {
            __type: "buffered",
            intermediate: nextAccounts,
            promise: cacheEntry.promise,
          };
          setAccounts(new PromiseResource(key, nextAccounts));
        }
      }
    };

    (async () => {
      subIds = addresses.map((address, idx) =>
        connection.onAccountChange(
          address,
          (account) => updateAccount(account, idx),
          "processed"
        )
      );
    })();

    return () => {
      for (const subId of subIds) {
        if (subId) connection.removeAccountChangeListener(subId);
      }
    };
  }, [connection, setAccounts, addresses]);

  return { accounts, addresses };
};
