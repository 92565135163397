import { PublicKey } from "@solana/web3.js";
import {
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  TOKEN_METADATA_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  MORTAR_PROGRAM_ID,
} from "./ids";

export const getReceiptKey = (
  purchaser: PublicKey,
  issuer: PublicKey
): PublicKey => {
  return PublicKey.findProgramAddressSync(
    [purchaser.toBuffer(), issuer.toBuffer()],
    MORTAR_PROGRAM_ID
  )[0];
};

export const getATAKeySync = (mint: PublicKey, owner: PublicKey): PublicKey => {
  return PublicKey.findProgramAddressSync(
    [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
  )[0];
};

export const getMetadataKey = (mint: PublicKey): PublicKey => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM_ID
  )[0];
};

export const getMasterEditionKey = (mint: PublicKey): PublicKey => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from("edition"),
    ],
    TOKEN_METADATA_PROGRAM_ID
  )[0];
};
