import { styled } from "@stitches/react";
import * as Select from "@radix-ui/react-select";

export const SelectTrigger = styled(Select.SelectTrigger, {
  all: "unset",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  fontSize: 13,
  lineHeight: 1,
  height: 35,
  gap: 5,
  backgroundColor: "rgba(43, 43, 43, 0.95)",
  width: "100%",

  "&:hover": {
    animation: "inherit",
    backgroundColor: "rgba(43, 43, 43, 0.75)",
  },
});

export const SelectContent = styled(Select.Content, {
  overflow: "hidden",
  backgroundColor: "#2B2B2B",
  borderRadius: 6,
  boxShadow:
    "0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)",
});

export const SelectViewport = styled(Select.Viewport, {
  padding: 5,
  backgroundColor: "rgba(#2B2B2B, 0.95)",
});

export const SelectItem = styled(Select.Item, {
  all: "unset",
  fontSize: 13,
  lineHeight: 1,
  // color: violet.violet11,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 25,
  padding: "0 35px 0 25px",
  position: "relative",
  userSelect: "none",

  "&:focus": {
    backgroundColor: "var(--primary-color)",
    color: "black",
  },
});
