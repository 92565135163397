import React from "react";
import { keyframes, styled } from "@stitches/react";

export const Button = styled("button", {
  width: "100%",
  fontSize: 16,
  height: 48,
  borderRadius: 8,
  borderWidth: 2,
  cursor: "pointer",
});

const activate = keyframes({
  "0%": { color: "#000", backgroundColor: "#ddd" },
  "100%": { color: "#000", backgroundColor: "#fff" },
});

export const ContrastButton = styled(Button, {
  backgroundColor: "#ddd",
  color: "#000",

  "&:hover": {
    backgroundColor: "#fff",
    animation: `${activate} 100ms`,
  },

  "&:disabled": {
    backgroundColor: "#ddd",
    animation: "none",
  },
});

export const GradientWrapper = styled("span", {
  background: "linear-gradient(45deg, #9945FF, #14F195)",
  padding: 3,
  borderRadius: 8,
});

export const GradientInner = styled(Button, {
  backgroundColor: "#fff",
  color: "#000",

  "&:hover": {
    backgroundColor: "#fff",
    color: "#000",
    animation: "none",
  },

  "&:disabled": {
    backgroundColor: "#1c1c1e",
    color: "#636366",
    animation: "none",
  },
});

export const GradientButton = (props: any) => {
  return (
    <GradientWrapper style={props.disabled ? { background: "none" } : {}}>
      <GradientInner {...props} />
    </GradientWrapper>
  );
};
