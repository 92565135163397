import { Transaction } from "@solana/web3.js";
import { getATAKeySync } from "./accounts";
import {
  MORTAR_MINT_ID,
  MORTAR_MINT_MULTIPLIER,
  MORTAR_MINT_TOTAL,
} from "./ids";
import { AccountLayout } from "@solana/spl-token";
import { notification } from "./common";
import { sendSignedTransaction } from "./transactions";

export const sendPaymentTransaction = async ({
  connection,
  wallet,
  setIsModalVisible,
  quantity,
  difference,
  transactionUrl,
}) => {
  const publicKey = wallet.publicKey;

  let transaction: Transaction;
  try {
    if (difference > 0) {
      const usdcATA = getATAKeySync(MORTAR_MINT_ID, publicKey);
      const usdcInfo = await connection.getAccountInfo(usdcATA, "processed");
      if (usdcInfo === null) {
        throw new Error(
          `It doesn't look like you have any USDC in your associated token account`
        );
      }

      const usdcAcc = AccountLayout.decode(usdcInfo.data);
      if (usdcAcc.amount < difference * MORTAR_MINT_TOTAL) {
        throw new Error(
          `It doesn't look like you have enough USDC in your associated token account. Found ${
            Number(usdcAcc.amount) / MORTAR_MINT_MULTIPLIER
          } USDC`
        );
      }
    }

    const response = await fetch(transactionUrl, {
      method: "POST",
      body: JSON.stringify({
        account: publicKey.toBase58(),
        quantity,
      }),
    });
    console.log(response);

    if (!response.ok) {
      const message = await response.text();
      throw new Error(message || response.statusText);
    }

    const { transaction: base64Transaction } = await response.json();
    console.log(base64Transaction);

    transaction = Transaction.from(Buffer.from(base64Transaction, "base64"));
  } catch (err) {
    console.error(err);
    notification.error({
      message: "Transaction building failed",
      description: err.message,
    });
    return null;
  }

  let signedTransaction;
  try {
    signedTransaction = await wallet.signTransaction(transaction);
  } catch (err) {
    notification.error({
      message: "Transacation signing failed",
      description: err.message,
    });
    return null;
  }

  try {
    const { txid } = await sendSignedTransaction({
      signedTransaction,
      connection,
    });
    console.log(txid);

    setIsModalVisible(true);
    return txid;
  } catch (err) {
    notification.error({
      message: "Transaction sending failed",
      description: err.message,
    });
    return null;
  }
};
