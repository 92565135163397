import { useWindowDimensions } from "../AppBar";
import React from "react";

const PreorderLayout = ({ children }: { children: any }) => {
  const { width } = useWindowDimensions();
  if (width <= 768) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 16,
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </div>
  );
};

export default PreorderLayout;
