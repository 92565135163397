import React from "react";

import { CheckIcon, CopyIcon } from "./Icons";

export const CopyText = ({
  text,
  style,
}: {
  text: string;
  style?: React.CSSProperties;
}) => {
  const [copied, setCopied] = React.useState(null);

  return (
    <button
      style={{
        fontWeight: 600,
        letterSpacing: "-0.02em",
        padding: 10,
        height: "inherit",
        border: "none",
        borderRadius: "50%",
        ...style,
      }}
      onClick={() => {
        navigator.clipboard.writeText(text);
        const timer = setTimeout(() => {
          setCopied((t) => (t === timer ? null : t));
        }, 500);
        setCopied(timer);
      }}
    >
      {copied ? <CheckIcon /> : <CopyIcon />}
    </button>
  );
};

export const CopyableText = ({
  display,
  text,
}: {
  display: React.ReactNode;
  text: string;
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {display}
      <CopyText text={text} style={{ marginLeft: 3 }} />
    </div>
  );
};
