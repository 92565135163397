import React from "react";
import { useWindowDimensions } from "../AppBar";

import PreorderLayout from "../Preorder/PreorderLayout";
import { Spin } from "../Spin";

const FallbackLayout: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { width } = useWindowDimensions();
  const columnWidth = width <= 768 ? "100%" : "50%";

  return (
    <PreorderLayout>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          width: columnWidth,
        }}
      >
        {children}
        <Spin />
      </div>
    </PreorderLayout>
  );
};

export default FallbackLayout;
