import React from "react";

const NoRefund = () => {
  return (
    <>
      <div className="text-subtitle">
        Hmm! There does not appear to be a deposit associated with this wallet.
        Confirm that you’ve connected with the correct wallet and try again.
      </div>
    </>
  );
};

export default NoRefund;
