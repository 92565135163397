import React, { useCallback } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

import { DotsVerticalIcon } from "../Icons";
import { PopoverContent } from "../CurrentUserBadge";
import { IconButton } from "../IconButton";

export interface ConnectButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  allowWalletChange?: boolean;
  className?: string;
}

export const ConnectButton = (props: ConnectButtonProps) => {
  const { children, disabled, allowWalletChange, className, ...rest } = props;
  const { wallet, connect, connected } = useWallet();
  const { setVisible } = useWalletModal();
  const open = useCallback(() => setVisible(true), [setVisible]);

  const handleClick = useCallback(
    () => (wallet ? connect().catch(() => undefined) : open()),
    [wallet, connect, open]
  );

  // only show if wallet selected or user connected

  if (!wallet || !allowWalletChange) {
    return (
      <button
        className={className || "connector"}
        {...rest}
        onClick={(e) => {
          props.onClick ? props.onClick(e) : null;
          handleClick();
        }}
        disabled={connected && disabled}
        style={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <>
      <button
        className={className || "connector"}
        onClick={handleClick}
        disabled={connected && disabled}
      >
        Connect
      </button>
      <IconButton onClick={open} style={{ padding: 5, marginLeft: 5 }}>
        <DotsVerticalIcon />
      </IconButton>
    </>
  );
};
