import React from "react";

import { AppBar } from "../AppBar";

export const AppLayout = React.memo(function AppLayoutImpl(props: any) {
  return (
    <>
      <div id={"main-layout"}>
        <div id={"width-layout"}>
          <div
            className="layout-content"
            style={{
              padding: "12px 48px ",
              flex: "unset",
              borderBottom: "1px solid #2A2A2A",
            }}
          >
            <div className="App-Bar">
              <AppBar />
            </div>
          </div>
          <div
            className="layout-content"
            style={{
              paddingLeft: "96px",
              paddingRight: "96px",
              paddingBottom: "30px",
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
});
