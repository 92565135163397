import { PublicKey } from "@solana/web3.js";

export { TOKEN_PROGRAM_ID } from "@solana/spl-token";

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export const MORTAR_PROGRAM_ID = new PublicKey(
  "781wH11JGQgEoBkBzuc8uoQLtp8KxeHk1yZiS1JhFYKy"
);

export const MORTAR_MINT_MULTIPLIER = 1_000_000;
export const MORTAR_MINT_AMOUNT = 100;
export const MORTAR_MINT_TOTAL = MORTAR_MINT_AMOUNT * MORTAR_MINT_MULTIPLIER;

export const MORTAR_ISSUER_KEY = new PublicKey(process.env.MORTAR_ISSUER_KEY);

export const MORTAR_MINT_ID = new PublicKey(process.env.MORTAR_MINT_ID);

export const LAMBDA_PAYER_KEY = new PublicKey(process.env.LAMBDA_PAYER_KEY);

export const LAMBDA_PURCHASE_URL = process.env.LAMBDA_PURCHASE_URL;

export const LAMBDA_REFUND_URL = process.env.LAMBDA_REFUND_URL;

export const LAMBDA_PURCHASE_URL_PAYER = process.env.LAMBDA_PURCHASE_URL_PAYER;

export const LAMBDA_REFUND_URL_PAYER = process.env.LAMBDA_REFUND_URL_PAYER;

export const LAMBDA_UPDATE_URL_PAYER = process.env.LAMBDA_UPDATE_URL_PAYER;

export const LAMBDA_PREORDERS_URL = process.env.LAMBDA_PREORDERS_URL;

export const LAMBDA_FORM_URL = process.env.LAMBDA_FORM_URL;

export const LAMBDA_EMAIL_URL = process.env.LAMBDA_EMAIL_URL;

export const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY;

export const LAMBDA_STRIPE_URL = process.env.LAMBDA_STRIPE_URL;

export const LAMBDA_FORM_CC_URL = process.env.LAMBDA_FORM_CC_URL;

export const MORTAR_MINT_URL = process.env.MORTAR_MINT_URL;

export const TWITTER_ADD_TO_CART_EVENT_ID =
  process.env.TWITTER_ADD_TO_CART_EVENT_ID;
export const TWITTER_PREORDER_PURCHASE_EVENT_ID =
  process.env.TWITTER_PREORDER_PURCHASE_EVENT_ID;
