import * as React from "react";
import { styled, keyframes } from "@stitches/react";

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const LoaderOuter = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});

export const LoaderInner = styled("div", {
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
  border: "4px solid transparent",
  borderTopColor: "gray",
  animation: `${spin} 1s linear infinite`,
});

export const Spin = (
  props: React.HTMLAttributes<HTMLDivElement> & { style?: React.CSSProperties }
) => {
  const { style, ...rest } = props;
  const d = { width: "50px", height: "50px" };
  return (
    <LoaderOuter>
      <LoaderInner style={Object.assign(d, style)} {...rest} />
    </LoaderOuter>
  );
};
