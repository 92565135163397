import React from "react";
import { PublicKey } from "@solana/web3.js";
import { AccountLayout } from "@solana/spl-token";

import {
  MORTAR_ISSUER_KEY,
  MORTAR_MINT_ID,
  TOKEN_PROGRAM_ID,
} from "../../utils/ids";
import { getATAKeySync, getReceiptKey } from "../../utils/accounts";
import { useSubscribeAccountInfo } from "../accounts";
import { useConnection } from "../ConnectionContext";
import { usePromiseResult } from "../../utils/suspense";

export const useReceiptInfo = (publicKey?: PublicKey) => {
  const receiptKey = React.useMemo(
    () => getReceiptKey(publicKey, MORTAR_ISSUER_KEY),
    [publicKey]
  );
  const { account: receiptInfo } = useSubscribeAccountInfo(receiptKey);
  const hasReceipt = receiptInfo.read() && receiptInfo.read().data.length > 0;
  return React.useMemo(
    () => ({ receiptInfo, receiptKey, hasReceipt }),
    [hasReceipt, receiptInfo, receiptKey]
  );
};

export const useReceiptATAInfo = (publicKey?: PublicKey) => {
  const { receiptKey } = useReceiptInfo(publicKey);
  const receiptATAKey = React.useMemo(
    () => getATAKeySync(MORTAR_MINT_ID, receiptKey),
    [receiptKey]
  );
  const { account: receiptATAInfo } = useSubscribeAccountInfo(receiptATAKey);
  const hasReceiptATA =
    receiptATAInfo.read() && receiptATAInfo.read().data.length > 0;

  return React.useMemo(
    () => ({ receiptATAInfo, receiptATAKey, hasReceiptATA }),
    [hasReceiptATA, receiptATAInfo, receiptATAKey]
  );
};

const SagaPassMints = require("./saga-pass-mints.json");
const SagaPassMintLookup = new Set<string>(SagaPassMints);

export const useSagaPassInfo = (publicKey?: PublicKey) => {
  const connection = useConnection();
  const tokenAccountsByOwner = usePromiseResult(
    `tokenAccountsByOwner-${publicKey}`,
    () =>
      connection.getTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      })
  );
  const tokenAccountsSync = tokenAccountsByOwner.read();
  const hasSagaPass: undefined | string = (tokenAccountsSync?.value || [])
    .map(({ account }) => {
      const parsed = AccountLayout.decode(account.data);
      const mint = parsed.mint.toBase58();
      return mint;
    })
    .reduce((acc: undefined | string, mint: string) => {
      if (acc !== undefined) return acc;
      if (SagaPassMintLookup.has(mint)) return mint;
    }, undefined);

  return React.useMemo(() => ({ hasSagaPass }), [hasSagaPass]);
};
