import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  redirect,
  Navigate,
} from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";
import { SkeletonTheme } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

import { CoingeckoProvider } from "../contexts/coingecko";
import { ConnectionProvider } from "../contexts/ConnectionContext";
import { WalletProvider } from "../contexts/WalletContext";
import { LoaderProvider } from "./Loader";
import { ToastProvider } from "./Toast";
import { AppLayout } from "./Layout";

import { SignupView } from "../views/SignupView";
import { PreorderView } from "../views/PreorderView";
import { PurchaseQR, RefundQR } from "../views/QRView";
import { RefundView } from "../views/RefundView";
import { OrderStatusView } from "../views/OrderStatusView";
import { UpdateView } from "../views/UpdateView";
import ErrorPage from "../views/404";
import { TwitterConversionProvider } from "../contexts/TwitterConversionContext";

export const Wrap = ({ children }: { children: React.ReactNode }) => {
  return <div className="mortar">{children}</div>;
};

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

const PageTrackingProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const location = useLocation();

  React.useEffect(() => {
    const page_path = location.pathname + location.search;

    (window.dataLayer || []).push("event", "page_view", {
      page_path,
    });
  }, [location, location.pathname + location.search]);

  return children;
};

export const App = () => {
  return (
    <BrowserRouter>
      <PageTrackingProvider>
        <TwitterConversionProvider>
          <Tooltip.Provider delayDuration={100} skipDelayDuration={0}>
            <SkeletonTheme baseColor="#2B2B2B" highlightColor="#444">
              <ToastProvider>
                <ConnectionProvider>
                  <CoingeckoProvider>
                    <LoaderProvider>
                      <WalletProvider>
                        <AppLayout>
                          <Wrap>
                            <Routes>
                              <Route
                                path="/"
                                element={
                                  <Navigate to="/manage" replace={true} />
                                }
                              />
                              <Route path="/manage" element={<RefundView />} />
                              <Route path="/qr/refund" element={<RefundQR />} />
                              <Route path="*" element={<ErrorPage />} />
                            </Routes>
                          </Wrap>
                        </AppLayout>
                      </WalletProvider>
                    </LoaderProvider>
                  </CoingeckoProvider>
                </ConnectionProvider>
              </ToastProvider>
            </SkeletonTheme>
          </Tooltip.Provider>
        </TwitterConversionProvider>
      </PageTrackingProvider>
    </BrowserRouter>
  );
};

export default App;
