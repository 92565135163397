import React from "react";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import { AccountLayout } from "@solana/spl-token";

import {
  LAMBDA_UPDATE_URL_PAYER,
  MORTAR_MINT_AMOUNT,
  MORTAR_MINT_TOTAL,
} from "../utils/ids";

import { useConnection } from "../contexts/ConnectionContext";
import { DepositProvider, useDeposit } from "../contexts/DepositContext";
import { useWindowDimensions } from "../components/AppBar";
import { decLoading, incLoading, useLoading } from "../components/Loader";

import PreorderLayout from "../components/Preorder/PreorderLayout";
import { ConnectButton } from "../components/buttons/ConnectedButton";
import DepositModal from "../components/modals/DepositModal";
import {
  PreorderProvider,
  REQUEST_TYPES,
  usePreorderData,
} from "../contexts/PreorderContext";

import { isInvalidAmount, PREORDER_MAX } from "../utils/preorderUtils";
import { notification } from "../utils";
import { sendPaymentTransaction } from "../utils/sendPaymentTransaction";

import { GradientButton } from "../components/buttons/GradientButton";
import { StyledSeparator } from "../components/shared/StylingComponents";
import PreorderAmount from "../components/Preorder/PreorderAmount";
import RefundButton from "../components/buttons/RefundButton";
import NoRefund from "../components/Refund/NoRefund";
import { RefundInfo } from "../components/shared/InfoComponents";
import FallbackLayout from "../components/shared/FallbackLayout";
import TransactionList from "../components/Refund/TransactionList";

const RefundViewImpl = ({ wallet }: { wallet: WalletContextState }) => {
  const connection = useConnection();
  const { setLoading } = useLoading();

  const {
    hasPreorder,
    hasReceipt,
    receiptInfo,
    hasReceiptATA,
    receiptATAInfo,
    preorderTransactions,
  } = usePreorderData();

  const initialAmount = (function () {
    if (!hasReceiptATA) return 0;
    const receiptATA = AccountLayout.decode(receiptATAInfo.read().data);
    return Math.floor(Number(receiptATA.amount / BigInt(MORTAR_MINT_TOTAL)));
  })();

  const { setIsModalVisible, preorderAmountStr } = useDeposit();

  const difference = (function () {
    if (isInvalidAmount(preorderAmountStr)) return 0;
    const preorderAmount = Number(preorderAmountStr);
    if (preorderAmount > PREORDER_MAX) return 0;
    return preorderAmount - initialAmount;
  })();

  const onUpdateClick = () => {
    const wrap = async () => {
      setLoading(incLoading);

      try {
        const txid = await sendPaymentTransaction({
          connection,
          wallet,
          setIsModalVisible,
          quantity: Number(preorderAmountStr),
          difference,
          transactionUrl: LAMBDA_UPDATE_URL_PAYER,
        });

        if (!txid) return;

        // TODO: submit form if changed!
      } catch (err) {
        notification.error({
          message: "Update failed",
          description: err.message,
        });
      } finally {
        setLoading(decLoading);
      }
    };
    wrap();
  };

  const { width } = useWindowDimensions();
  const columnWidth = width <= 768 ? "100%" : "50%";

  return (
    <PreorderLayout>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          width: columnWidth,
        }}
      >
        <RefundInfo />
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          width: columnWidth,
          marginLeft: width <= 768 ? 0 : 20,
        }}
      >
        {width > 768 && <div style={{ height: 40 }} />}

        {hasReceipt && hasReceiptATA ? (
          <>
            <div>Your pre-order</div>
            <div style={{ height: 10 }} />

            <PreorderAmount initialAmount={initialAmount} />
            <div style={{ height: 10 }} />

            <RefundButton receiptInfo={receiptInfo.read()} />

            <div style={{ height: 20 }} />
            <StyledSeparator />
            <div style={{ height: 30 }} />

            <div className="text-subtitle" style={{ textAlign: "center" }}>
              {difference >= 0 ? "Total" : "Refund"} due:&nbsp;
              <span style={{ color: "white", fontWeight: 600 }}>
                {difference * MORTAR_MINT_AMOUNT} USDC
              </span>
            </div>

            <div style={{ height: 10 }} />

            <GradientButton
              style={{ width: "100%", fontSize: 16 }}
              disabled={isInvalidAmount(preorderAmountStr) || difference === 0}
              onClick={onUpdateClick}
            >
              Update my order
            </GradientButton>
          </>
        ) : hasPreorder && preorderTransactions.length ? (
          <TransactionList
            hasPreorder={hasPreorder}
            preorderTransactions={preorderTransactions}
          />
        ) : (
          <NoRefund />
        )}
      </div>
    </PreorderLayout>
  );
};

export const RefundView = () => {
  const wallet = useWallet();
  const publicKey = wallet.publicKey;

  const { width } = useWindowDimensions();
  const columnWidth = width <= 768 ? "100%" : "50%";

  const Fallback = () => {
    return (
      <PreorderLayout>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            width: columnWidth,
          }}
        >
          <RefundInfo />

          <div style={{ width: 250, display: "flex", flexDirection: "column" }}>
            <ConnectButton>
              Connect wallet
              {wallet.wallet && (
                <>
                  &nbsp;&nbsp;
                  <img
                    src={wallet.wallet.adapter.icon}
                    style={{ maxHeight: 25 }}
                    alt=""
                  />
                </>
              )}
            </ConnectButton>
          </div>
        </div>

        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            width: columnWidth,
            marginLeft: width <= 768 ? 0 : 20,
          }}
        ></div>
      </PreorderLayout>
    );
  };

  if (!publicKey) {
    return <Fallback />;
  }

  return (
    <React.Suspense fallback={<Fallback />}>
      <DepositProvider>
        <React.Suspense
          fallback={
            <FallbackLayout>
              <RefundInfo />
            </FallbackLayout>
          }
        >
          <PreorderProvider
            publicKey={publicKey}
            requestType={REQUEST_TYPES.transactions}
          >
            <RefundViewImpl wallet={wallet} />
            <DepositModal title={"Update received!"} />
          </PreorderProvider>
        </React.Suspense>
      </DepositProvider>
    </React.Suspense>
  );
};
