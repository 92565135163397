import React from "react";
import { useDeposit } from "../../contexts/DepositContext";
import {
  isBadAmount,
  PREORDER_CONTACT,
  PREORDER_MAX,
} from "../../utils/preorderUtils";

import { IconButton } from "../IconButton";
import { MinusIcon, PlusIcon } from "../Icons";

const PreorderAmount = ({
  initialAmount,
}: {
  initialAmount: number | null;
}) => {
  const { preorderAmountStr, setPreorderAmount } = useDeposit();
  React.useEffect(() => {
    if (initialAmount) setPreorderAmount(initialAmount.toString());
  }, []);
  const preorderAmount = Number(preorderAmountStr);
  const invalidAmount = isBadAmount(preorderAmountStr);
  const hasError = invalidAmount || preorderAmount > PREORDER_MAX;

  return (
    <div>
      <div className="text-subtitle">Quantity</div>

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <input
          style={{
            fontSize: 16,
            textAlign: "center",
            color: hasError ? "red" : "white",
          }}
          value={preorderAmountStr}
          onChange={(e) => setPreorderAmount(e.target.value)}
          autoComplete="on"
        />

        <IconButton
          style={{ padding: 10, border: "none" }}
          onClick={() =>
            setPreorderAmount((p) => {
              if (isNaN(Number(p))) return p;
              else return (Number(p) - 1).toString();
            })
          }
          disabled={isNaN(preorderAmount) || preorderAmount === 0}
        >
          <MinusIcon size={20} />
        </IconButton>
        <IconButton
          style={{ padding: 10, border: "none" }}
          onClick={() =>
            setPreorderAmount((p) => {
              if (isNaN(Number(p))) return p;
              else return (Number(p) + 1).toString();
            })
          }
          disabled={isNaN(preorderAmount) || preorderAmount === PREORDER_MAX}
        >
          <PlusIcon size={20} />
        </IconButton>
      </div>

      {invalidAmount && <span style={{ color: "red" }}>Invalid amount</span>}
      {!invalidAmount && preorderAmount > PREORDER_MAX && (
        <>
          <div style={{ color: "red" }}>The maximum is {PREORDER_MAX}</div>
        </>
      )}
      {!invalidAmount && preorderAmount >= PREORDER_MAX && (
        <>
          <div style={{ fontSize: 14 }}>
            If you&apos;d like to order more than {PREORDER_MAX}, please contact
            our biz dev team to speak with someone:{" "}
            <a href={`mailto:${PREORDER_CONTACT}`}>{PREORDER_CONTACT}</a>
          </div>
        </>
      )}
    </div>
  );
};

export default PreorderAmount;
