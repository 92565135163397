import React from "react";

import {
  IDeveloperPreorderData,
  useLocalStoragePreorderData,
} from "../hooks/useLocalStoragePreorderData";

type AuthUser = { uid: string };

export type DepositContextState = {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;

  developerStr: string;
  setDeveloperStr: React.Dispatch<React.SetStateAction<string>>;

  githubUser: AuthUser | string | null;
  setGithubUser: React.Dispatch<React.SetStateAction<AuthUser | string | null>>;

  twitterUser: AuthUser | string | null;
  setTwitterUser: React.Dispatch<
    React.SetStateAction<AuthUser | string | null>
  >;

  emailStr: string;
  setEmailStr: React.Dispatch<React.SetStateAction<string>>;

  preorderAmountStr: string;
  setPreorderAmount: React.Dispatch<React.SetStateAction<string>>;

  companyStr: string;
  setCompanyStr: React.Dispatch<React.SetStateAction<string>>;
  setPreorderData: React.Dispatch<React.SetStateAction<IDeveloperPreorderData>>;
};

export const DepositContext = React.createContext<DepositContextState | null>(
  null
);

export const useDeposit = () => {
  const context = React.useContext(DepositContext);
  if (context === null) {
    throw new Error(`useDeposit must be used with a provider`);
  }
  return context;
};

interface DepositProviderProps {
  children?: React.ReactNode;
}

export const DepositProvider: React.FC<DepositProviderProps> = ({
  children,
}) => {
  const [
    preorderData,
    {
      setPreorderData,
      setEmailStr,
      setDeveloperStr,
      setGithubUser,
      setTwitterUser,
      setCompanyStr,
    },
  ] = useLocalStoragePreorderData();

  const [preorderAmountStr, setPreorderAmount] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const {
    email: emailStr,
    developer: developerStr,
    githubUser: githubUser,
    twitterUser: twitterUser,
    company: companyStr,
  } = preorderData;

  const depositValues = React.useMemo(
    () => ({
      developerStr,
      setDeveloperStr,
      isModalVisible,
      setIsModalVisible,
      githubUser,
      setGithubUser,
      twitterUser,
      setTwitterUser,
      emailStr: emailStr?.toLowerCase(),
      setEmailStr,
      preorderAmountStr,
      setPreorderAmount,
      companyStr,
      setCompanyStr,
      setPreorderData,
    }),
    [
      companyStr,
      developerStr,
      emailStr,
      githubUser,
      isModalVisible,
      preorderAmountStr,
      setCompanyStr,
      setDeveloperStr,
      setEmailStr,
      setGithubUser,
      setPreorderData,
      setTwitterUser,
      twitterUser,
    ]
  );

  return (
    <DepositContext.Provider value={depositValues}>
      {children}
    </DepositContext.Provider>
  );
};
