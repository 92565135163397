import * as React from "react";
import { styled, keyframes } from "@stitches/react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { DialogIconButton } from "./Dialog";
import { CrossIcon } from "./Icons";

const VIEWPORT_PADDING = 25;

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: "translateX(0)" },
});

const swipeOut = keyframes({
  from: { transform: "translateX(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const StyledViewport = styled(ToastPrimitive.Viewport, {
  position: "fixed",
  bottom: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 420,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: 2147483647,
  outline: "none",
});

const StyledToast = styled(ToastPrimitive.Root, {
  backgroundColor: "#2B2B2B",
  borderRadius: 6,
  boxShadow:
    "0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)",
  padding: 15,
  display: "grid",
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: "auto max-content",
  columnGap: 15,
  alignItems: "center",

  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in forwards`,
    },
    '&[data-swipe="move"]': {
      transform: "translateX(var(--radix-toast-swipe-move-x))",
    },
    '&[data-swipe="cancel"]': {
      transform: "translateX(0)",
      transition: "transform 200ms ease-out",
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out forwards`,
    },
  },
});

const StyledTitle = styled(ToastPrimitive.Title, {
  gridArea: "title",
  marginBottom: 5,
  fontWeight: 500,
  // color: slate.slate12,
  fontSize: 15,
  display: "flex",
  flexDirection: "row",
});

const StyledDescription = styled(ToastPrimitive.Description, {
  gridArea: "description",
  margin: 0,
  // color: slate.slate11,
  fontSize: 13,
  lineHeight: 1.3,
  marginBottom: 10,
  wordWrap: "break-word",
  maxWidth: 300,
});

const StyledAction = styled(ToastPrimitive.Action, {
  gridArea: "action",
});

const StyledClose = styled(ToastPrimitive.Close, {
  border: 0,
  borderRadius: "50%",
  height: 25,
  width: 25,
});

// Exports
export const Toast: any = React.forwardRef((toastProps, forwardedRef) => {
  const [toasts, setToasts] = React.useState([]);

  React.useImperativeHandle(forwardedRef, () => ({
    publish: (t) => setToasts((ts) => [...ts, t]),
  }));

  return (
    <>
      {toasts.map((toast, index) => (
        <StyledToast key={index} {...toastProps}>
          <ToastTitle>
            <span
              style={{
                borderRadius: "100%",
                paddingRight: 10,
              }}
            >
              {toast && toast.icon}
            </span>
            <div
              style={{
                wordWrap: "break-word",
                maxWidth: 265,
              }}
            >
              {toast && toast.message}
            </div>
          </ToastTitle>
          <ToastDescription>{toast && toast.description}</ToastDescription>
          <ToastClose>
            <CrossIcon />
          </ToastClose>
        </StyledToast>
      ))}
    </>
  );
});

Toast.displayName = "Toast";

export const toastRef: any = React.createRef();
export const ToastProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <ToastPrimitive.Provider duration={3000}>
      {children}
      <Toast ref={toastRef}>Saved successfully!</Toast>
      <ToastViewport />
    </ToastPrimitive.Provider>
  );
};

export const ToastViewport = StyledViewport;
export const ToastRoot = StyledToast;
export const ToastTitle = StyledTitle;
export const ToastDescription = StyledDescription;
export const ToastAction = StyledAction;
export const ToastClose = StyledClose;
