import React from "react";
import { PublicKey } from "@solana/web3.js";
import useAsyncEffect from "use-async-effect";

import { LAMBDA_PREORDERS_URL } from "../../utils/ids";
import {
  useReceiptATAInfo,
  useReceiptInfo,
  useSagaPassInfo,
} from "./useReceipts";
import { sendPostRequestToEndpoint } from "../../utils/preorderUtils";
import { useDeposit } from "../DepositContext";
import { useLocalStorageState } from "../../utils";

export enum REQUEST_TYPES {
  developer = "developer",
  transactions = "transactions",
}

export interface ITransactionData {
  numSagaPhonesPreordered?: number;
  transactionSignature?: string;
  blockTime?: number;
  receiptKey?: string;
  receiptATAInfo?: string;
}

export type PreorderContextState = {
  hasPreorder: boolean;
  receiptInfo;
  receiptKey;
  hasReceipt: boolean;
  receiptATAInfo;
  receiptATAKey;
  hasReceiptATA: boolean;
  hasSagaPass: undefined | string;
  preorderTransactions?: ITransactionData[];
};

export const PreorderContext = React.createContext<PreorderContextState | null>(
  null
);

export const usePreorderData = () => {
  const context = React.useContext(PreorderContext);
  if (context === null) {
    throw new Error(`usePreorderData() must be used with a provider`);
  }
  return context;
};

interface PreorderProviderProps {
  requestType?: REQUEST_TYPES;
  publicKey?: PublicKey;
  children?: React.ReactNode;
}

const preorderDataForPublicKey = new Set<string>();

export const PreorderProvider: React.FC<PreorderProviderProps> = ({
  requestType,
  publicKey,
  children,
}) => {
  const [preorderTransactions, setPreorderTransactions] = useLocalStorageState<
    ITransactionData[]
  >("preorderTransactions", []);
  const { setPreorderData } = useDeposit();

  const { receiptInfo, receiptKey, hasReceipt } = useReceiptInfo(publicKey);
  const { receiptATAInfo, receiptATAKey, hasReceiptATA } =
    useReceiptATAInfo(publicKey);
  const { hasSagaPass } = useSagaPassInfo(publicKey);
  const hasPreorder = hasReceipt || hasReceiptATA || !!hasSagaPass;

  useAsyncEffect(
    async (isMounted) => {
      const controller = new AbortController();
      // Don't continue if the component isn't mounted, during SSR or
      // initialPreorderData was set.
      if (
        !isMounted() ||
        typeof window === "undefined" ||
        !publicKey ||
        preorderDataForPublicKey.has(`${publicKey}-${requestType}`) ||
        !hasPreorder
      ) {
        return;
      }
      // Send the Public Key and the request type with a POST request.
      const preorderResult = await sendPostRequestToEndpoint({
        queryURL: LAMBDA_PREORDERS_URL,
        data: JSON.stringify({ publicKey, requestType }),
        returnResponse: true,
        signal: controller.signal,
      });
      // Only set preorderData without any error.
      if (preorderResult.status === 200) {
        const newPreorderData = await preorderResult.json();
        if (!isMounted()) {
          return;
        }
        if (requestType === REQUEST_TYPES.developer) {
          setPreorderData(newPreorderData);
        } else {
          setPreorderTransactions(newPreorderData);
        }
        preorderDataForPublicKey.add(`${publicKey}-${requestType}`);
      }
      return () => {
        controller.abort();
      };
    },
    [publicKey, hasPreorder, requestType]
  );

  const preorderValues = React.useMemo(
    () => ({
      hasPreorder,
      receiptInfo,
      receiptKey,
      hasReceipt,
      receiptATAInfo,
      receiptATAKey,
      hasReceiptATA,
      hasSagaPass,
      preorderTransactions,
    }),
    [
      hasPreorder,
      hasReceipt,
      hasReceiptATA,
      hasSagaPass,
      preorderTransactions,
      receiptATAInfo,
      receiptATAKey,
      receiptInfo,
      receiptKey,
    ]
  );

  return (
    <PreorderContext.Provider value={preorderValues}>
      {children}
    </PreorderContext.Provider>
  );
};
