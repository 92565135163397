import { useConnection } from "../../contexts/ConnectionContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { decLoading, incLoading, useLoading } from "../Loader";
import { LAMBDA_REFUND_URL, LAMBDA_REFUND_URL_PAYER } from "../../utils/ids";
import { notification } from "../../utils";
import { explorerLinkFor } from "../../utils/transactions";
import { Button } from "./GradientButton";
import React from "react";
import { sendPaymentTransaction } from "../../utils/sendPaymentTransaction";

const RefundButton = ({ receiptInfo }: { receiptInfo: any }) => {
  const connection = useConnection();
  const wallet = useWallet();
  const publicKey = wallet.publicKey;
  const { setLoading } = useLoading();

  const onRefundClick = () => {
    const wrap = async () => {
      setLoading(incLoading);
      try {
        const refundUrl =
          receiptInfo.data.length > 8 + 64 + 1 + 8 + 7
            ? LAMBDA_REFUND_URL_PAYER
            : LAMBDA_REFUND_URL;

        const txid = await sendPaymentTransaction({
          connection,
          wallet,
          setIsModalVisible: () => undefined,
          quantity: 0, // unused
          difference: -1, // skip ata check
          transactionUrl: refundUrl,
        });

        if (!txid) return;

        // TODO: figma
        notification.success({
          message: "Refund succeeded",
          description: (
            <a
              href={explorerLinkFor(txid, connection)}
              target="_blank"
              rel="noreferrer"
            >
              View transaction on explorer
            </a>
          ),
        });
      } catch (err) {
        notification.error({
          message: "Refund failed",
          description: err.message,
        });
      } finally {
        setLoading(decLoading);
      }
    };
    wrap();
  };

  return <Button onClick={onRefundClick}>Get a refund</Button>;
};

export default RefundButton;
