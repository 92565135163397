import { WalletError } from "@solana/wallet-adapter-base";
import {
  WalletProvider as BaseWalletProvider,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "./WalletModalProvider";

import React, { FC, ReactNode, useCallback, useMemo } from "react";

import {
  PhantomWalletAdapter,
  GlowWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  BackpackWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  createDefaultAddressSelector,
  createDefaultAuthorizationResultCache,
  createDefaultWalletNotFoundHandler,
  SolanaMobileWalletAdapter,
} from "@solana-mobile/wallet-adapter-mobile";
import { useConnection } from "../ConnectionContext";
import { envFor } from "../../utils/transactions";
import { Cluster } from "@solana/web3.js";

export const WalletProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const connection = useConnection();
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new SlopeWalletAdapter(),
      new GlowWalletAdapter(),
      new BackpackWalletAdapter(),
      new SolanaMobileWalletAdapter({
        addressSelector: createDefaultAddressSelector(),
        appIdentity: {
          name: "Solana Mobile",
          uri: "https://preorders.solanamobile.com",
        },
        cluster: envFor(connection) as Cluster,
        authorizationResultCache: createDefaultAuthorizationResultCache(),
        onWalletNotFound: createDefaultWalletNotFoundHandler(),
      }),
    ],
    [connection.rpcEndpoint]
  );

  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);

  return (
    <BaseWalletProvider wallets={wallets as any} onError={onError} autoConnect>
      <WalletModalProvider>{children}</WalletModalProvider>
    </BaseWalletProvider>
  );
};

export type WalletSigner = Pick<
  WalletContextState,
  "publicKey" | "signTransaction" | "signAllTransactions" | "signMessage"
>;
