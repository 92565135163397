import * as React from "react";
import { AccountInfo, PublicKey } from "@solana/web3.js";

export const cachedPromises: Record<
  string,
  | undefined
  | { __type: "promise"; promise: Promise<void> }
  | { __type: "buffered"; intermediate: any; promise: Promise<void> }
  | { __type: "result"; result: any | null }
> = {};

export class PromiseResource<T> {
  key: string;
  cachedResult: T | null;

  constructor(key: string, cachedResult: T | null) {
    this.key = key;
    this.cachedResult = cachedResult;
  }

  read(): T | null {
    if (this.cachedResult === null) {
      const cacheEntry = cachedPromises[this.key];
      if (cacheEntry === undefined) {
        throw new Error(
          `Reading handle of unknown promise resource ${this.key}`
        );
      } else if (
        cacheEntry.__type === "promise" ||
        cacheEntry.__type === "buffered"
      ) {
        throw cacheEntry.promise;
      }
      this.cachedResult = cacheEntry.result;
    }
    return this.cachedResult;
  }
}

export function usePromiseResult<T>(
  key: string,
  toCache: () => Promise<T>
): PromiseResource<T> | null {
  // TODO: unify to something like?:
  //   return usePromiseResults(key, () => toCache().then(r => [r]));
  const cacheEntry = cachedPromises[key];

  if (cacheEntry === undefined) {
    const promise = toCache()
      .then((result: T) => {
        if (cachedPromises[key].__type === "promise") {
          cachedPromises[key] = { __type: "result", result };
        }
      })
      .catch((_) => {
        if (cachedPromises[key].__type === "promise") {
          cachedPromises[key] = { __type: "result", result: null };
        }
      });
    cachedPromises[key] = {
      __type: "promise",
      promise,
    };
  }

  return new PromiseResource<T>(key, null);
}

export function usePromiseResults<T>(
  key: string,
  toCache: () => Promise<Array<T> | null>
): PromiseResource<Array<T> | null> | null {
  const cacheEntry = cachedPromises[key];

  if (cacheEntry === undefined) {
    const promise = toCache()
      .then((result: Array<T>) => {
        const cacheEntry = cachedPromises[key];
        if (cacheEntry.__type === "promise") {
          cachedPromises[key] = { __type: "result", result };
        } else if (cacheEntry.__type === "buffered") {
          // if we already got some results (individual subscriptions), defer
          // to those, otherwise add in what we got back
          const mergedResult = cacheEntry.intermediate.map((m, idx) =>
            m === null ? result[idx] : m
          );
          cachedPromises[key] = {
            __type: "result",
            result: mergedResult,
          };
        }
      })
      .catch((_) => {
        // TODO: this is a bit weird... should we transition buffered to
        // result?
        cachedPromises[key] = { __type: "result", result: null };
      });
    cachedPromises[key] = {
      __type: "promise",
      promise,
    };
  }

  return new PromiseResource<Array<T> | null>(key, null);
}

export function usePDA<T>(
  nonce: string,
  publicKey: PublicKey,
  findPDA: (p: PublicKey) => Promise<T>
) {
  return usePromiseResult(`${nonce}-${publicKey.toBase58()}`, () =>
    findPDA(publicKey)
  ).read();
}

export const useDecodedMemo = (account: AccountInfo<Buffer>, decode) => {
  return React.useMemo(() => {
    if (!account) return null;
    return decode(account.data);
  }, [account]);
};
