import React, { useCallback, useEffect, useRef } from "react";

import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { AccountLayout, MintLayout } from "@solana/spl-token";
import * as Popover from "@radix-ui/react-popover";
import * as Select from "@radix-ui/react-select";
import * as Tooltip from "@radix-ui/react-tooltip";
import { styled, keyframes } from "@stitches/react";
import Jazzicon from "jazzicon";
import bs58 from "bs58";
import Skeleton from "react-loading-skeleton";

import { CheckIcon, ChevronDownIcon, ProfileIcon, GearIcon } from "../Icons";
import {
  SelectContent,
  SelectViewport,
  SelectItem,
  SelectTrigger,
} from "../Select";

import { MonospacedPublicKey } from "../MonospacedPublicKey";
import { CopyText } from "../CopyText";
import {
  ENDPOINTS,
  useConnectionConfig,
} from "../../contexts/ConnectionContext";
import { useSolPrice } from "../../contexts/coingecko";
import { useSubscribeAccountInfo } from "../../contexts/accounts";
import { formatNumber, formatUSD, shortenAddress } from "../../utils/common";
import { getATAKeySync } from "../../utils/accounts";
import { MORTAR_MINT_ID } from "../../utils/ids";
import { useQuerySearch } from "../../hooks/useQuerySearch";

const open = keyframes({
  "0%": { opacity: 0, transform: "scale(0)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

const close = keyframes({
  "0%": { opacity: 1, transform: "scale(1)" },
  "100%": { opacity: 0, transform: "scale(0)" },
});

export const PopoverContent = styled(Popover.Content, {
  opacity: 1,
  transformOrigin: "var(--radix-popover-content-transform-origin)",
  boxShadow:
    "0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)",
  borderRadius: 12,
  backgroundColor: "#111",
  padding: "12px 16px",
  '&[data-state="open"]': { animation: `${open} 200ms ease-out forwards` },
  '&[data-state="closed"]': { animation: `${close} 200ms ease-out forwards` },
});

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const TooltipContent = styled(Tooltip.Content, {
  backgroundColor: "#2B2B2B",
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: 15,
  lineHeight: 1,
  boxShadow:
    "0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)",
  // boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const TooltipArrow = styled(Tooltip.Arrow, {
  fill: "#2B2B2B",
});

export const Identicon = (props: {
  address?: string | PublicKey;
  style?: React.CSSProperties;
  className?: string;
  alt?: string;
}) => {
  const { style, className, alt } = props;
  const address =
    typeof props.address === "string"
      ? props.address
      : props.address?.toBase58();
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (address && ref.current) {
      try {
        ref.current.innerHTML = "";
        ref.current.className = className || "";
        ref.current.appendChild(
          Jazzicon(
            style?.width || 16,
            parseInt(bs58.decode(address).toString("hex").slice(5, 15), 16)
          )
        );
      } catch (err) {
        // TODO
      }
    }
  }, [address, style, className]);

  return (
    <div
      className="identicon-wrapper"
      title={alt}
      ref={ref as any}
      style={props.style}
    />
  );
};

export const Settings = ({ publicKey }: { publicKey: PublicKey }) => {
  return (
    <>
      <Identicon
        address={publicKey.toBase58()}
        style={{
          width: 48,
        }}
      />

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CopyText text={publicKey.toBase58()} style={{ marginRight: 3 }} />
        <MonospacedPublicKey address={publicKey} />
      </div>

      <span
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          width: "calc(100% + 32px)",
          marginTop: 10,
          marginBottom: 10,
        }}
      />
    </>
  );
};

const btnStyle: React.CSSProperties = {
  width: "100%",
  border: "none",
  height: 40,
  display: "inline-flex",
  justifyContent: "start",
  alignItems: "center",
  borderRadius: 8,
  padding: "0px 8px",
};

export const USDCBalance = () => {
  const { publicKey } = useWallet();
  const { account: usdcMint } = useSubscribeAccountInfo(MORTAR_MINT_ID);
  const usdcATA = React.useMemo(
    () => getATAKeySync(MORTAR_MINT_ID, publicKey),
    [publicKey]
  );
  const { account } = useSubscribeAccountInfo(usdcATA);

  const balance = React.useMemo(() => {
    if (!account.read()) {
      return null;
    }

    return (
      Number(AccountLayout.decode(account.read().data).amount) /
      Math.pow(10, Number(MintLayout.decode(usdcMint.read().data).decimals))
    );
  }, [account.read(), usdcMint.read()]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <span
        style={{
          fontWeight: 600,
        }}
      >
        {formatNumber.format(balance)} USDC
      </span>
    </div>
  );
};

export const CurrentUserBadge = (props: {
  showAddress?: boolean;
  iconSize?: number;
}) => {
  const { wallet, publicKey, disconnect } = useWallet();
  const { setVisible } = useWalletModal();
  const open = useCallback(() => setVisible(true), [setVisible]);

  if (!wallet || !publicKey) {
    return null;
  }
  const iconStyle: React.CSSProperties = {
    display: "flex",
    width: props.iconSize,
    borderRadius: 50,
  };

  let name = props.showAddress ? shortenAddress(`${publicKey}`) : "";
  const unknownWallet = wallet as any;
  if (unknownWallet.name && !props.showAddress) {
    name = unknownWallet.name;
  }

  let image = <Identicon address={publicKey?.toBase58()} style={iconStyle} />;

  if (unknownWallet.image) {
    image = <img src={unknownWallet.image} style={iconStyle} />;
  }

  return (
    <div className="wallet-wrapper">
      <Popover.Root>
        <PopoverContent align="end" sideOffset={20}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "15px",
            }}
          >
            <Settings publicKey={publicKey} />
            <div
              style={{
                width: 250,
                paddingLeft: "10px",
              }}
            >
              <h5
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  letterSpacing: "0.02em",
                  marginBottom: 4,
                }}
              >
                WALLET
              </h5>
              <button onClick={disconnect} style={btnStyle}>
                <div>Disconnect</div>
              </button>
              <button style={btnStyle} onClick={open}>
                Change wallet
              </button>
              <h5
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  letterSpacing: "0.02em",
                  marginBottom: 4,
                }}
              >
                BALANCE
              </h5>
              <div
                style={{
                  marginBottom: 10,
                  padding: "0px 8px",
                }}
              >
                <React.Suspense fallback={<Skeleton />}>
                  <USDCBalance />
                </React.Suspense>
              </div>
              <br />
            </div>
          </div>
        </PopoverContent>
        <Popover.Trigger asChild>
          <button className="wallet-key">
            {image}
            {name && (
              <span
                style={{
                  marginLeft: "0.5rem",
                  fontWeight: 600,
                }}
              >
                {name}
              </span>
            )}
          </button>
        </Popover.Trigger>
      </Popover.Root>
    </div>
  );
};

export const NetworkSettings = () => {
  const { endpoint } = useConnectionConfig();
  const routerSearchParams = useQuerySearch();

  return (
    <>
      <h5
        style={{
          color: "rgba(255, 255, 255, 0.7)",
          letterSpacing: "0.02em",
          marginBottom: 4,
        }}
      >
        NETWORK
      </h5>
      <Select.Root
        onValueChange={(network: string) => {
          // Reload the page, forward user selection to the URL querystring.
          // The app will be re-initialized with the correct network
          // (which will also be saved to local storage for future visits)
          // for all its lifecycle.
          routerSearchParams.set("network", network as any);
          console.log(
            `Set window search to ${network} ${routerSearchParams.toString()}: ${
              window.location.search
            }`
          );
          window.location.search = `?${routerSearchParams.toString()}`;
        }}
        value={endpoint.name}
      >
        <SelectTrigger>
          <Select.Value />
          <Select.Icon>
            <ChevronDownIcon />
          </Select.Icon>
        </SelectTrigger>
        <SelectContent>
          <SelectViewport>
            {ENDPOINTS.map(({ name }) => (
              <SelectItem value={name} key={name}>
                <Select.ItemText>{name}</Select.ItemText>
                <Select.ItemIndicator>
                  <CheckIcon />
                </Select.ItemIndicator>
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </Select.Root>
    </>
  );
};

export const CurrentUserBadgeMobile = (props: {
  showAddress?: boolean;
  iconSize?: number;
  closeModal?: any;
}) => {
  const { wallet, publicKey, disconnect } = useWallet();
  const { account } = useSubscribeAccountInfo(publicKey);
  const { setVisible } = useWalletModal();
  const open = useCallback(() => setVisible(true), [setVisible]);

  if (!wallet || !publicKey) {
    return null;
  }

  const iconStyle: React.CSSProperties = {
    display: "flex",
    width: props.iconSize,
    borderRadius: 50,
  };

  let name = props.showAddress ? shortenAddress(`${publicKey}`) : "";
  const unknownWallet = wallet as any;
  if (unknownWallet.name && !props.showAddress) {
    name = unknownWallet.name;
  }

  let image = <Identicon address={publicKey?.toBase58()} style={iconStyle} />;

  if (unknownWallet.image) {
    image = <img src={unknownWallet.image} style={iconStyle} />;
  }

  return (
    <div
      className="current-user-mobile-badge"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="mobile-badge">
        {image}
        {name && (
          <span
            style={{
              marginLeft: "0.5rem",
              fontWeight: 600,
            }}
          >
            {name}
          </span>
        )}
      </div>

      <button
        onClick={disconnect}
        style={{
          borderWidth: 1,
          borderColor: "rgba(255, 255, 255, 0.5)",
          height: 40,
          borderRadius: 20,
          padding: "0px 20px",
        }}
      >
        Disconnect
      </button>
    </div>
  );
};
