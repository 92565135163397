import {
  Commitment,
  Connection,
  Keypair,
  Transaction,
  TransactionInstruction,
  Blockhash,
  FeeCalculator,
  clusterApiUrl,
} from "@solana/web3.js";
import React, { useContext } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";

import { WalletSigner } from "./WalletContext";
import { envFor, sendSignedTransaction } from "../utils/transactions";
import { shortenAddress } from "../utils";

const CUSTOM_ENDPOINT = process?.env?.CUSTOM_ENDPOINT;

interface BlockhashAndFeeCalculator {
  blockhash: Blockhash;
  feeCalculator: FeeCalculator;
}

export type ENDPOINT_NAME = "mainnet-beta" | "testnet" | "devnet" | "localnet";

export type Endpoint = {
  name: ENDPOINT_NAME;
  url: string;
};

export const ENDPOINTS: Array<Endpoint> = [
  {
    name: "mainnet-beta",
    url: CUSTOM_ENDPOINT || clusterApiUrl("mainnet-beta"),
  },
  {
    name: "devnet",
    url: clusterApiUrl("devnet"),
  },
  {
    name: "mainnet-beta",
    url: "https://young-fluent-log.solana-mainnet.quiknode.pro/9532ac48a288e729c2516383f857f7684480c2ba/",
  },
];

const DEFAULT_IDX = parseInt(process.env.DEFAULT_ENDPOINT_IDX);
const DEFAULT_ENDPOINT = ENDPOINTS[DEFAULT_IDX];

interface ConnectionConfig {
  connection: Connection;
  endpoint: Endpoint;
}

const ConnectionContext = React.createContext<ConnectionConfig>({
  connection: new Connection(DEFAULT_ENDPOINT.url, "recent"),
  endpoint: DEFAULT_ENDPOINT,
});

export function ConnectionProvider({ children }: { children: any }) {
  const endpoint = DEFAULT_ENDPOINT;

  const { current: connection } = React.useRef(new Connection(endpoint.url));

  const contextValue = React.useMemo(() => {
    return {
      endpoint,
      connection,
    };
  }, []);

  return (
    <ConnectionContext.Provider value={contextValue}>
      {children}
    </ConnectionContext.Provider>
  );
}

export function useConnection() {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error("ConnectionContext must be used with a ConnectionProvider");
  }
  return context.connection as Connection;
}

export function useConnectionConfig() {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error("ConnectionContext must be used with a ConnectionProvider");
  }
  return {
    endpoint: context.endpoint,
  };
}

export const explorerLinkCForAddress = (
  key: string,
  connection: Connection,
  shorten = true
) => {
  return (
    <a
      href={`https://explorer.solana.com/address/${key}?cluster=${envFor(
        connection
      )}`}
      target="_blank"
      rel="noreferrer"
      title={key}
      style={{
        fontFamily: "Monospace",
        color: "#7448A3",
      }}
    >
      {shorten ? shortenAddress(key) : key}
    </a>
  );
};

export const getErrorForTransaction = async (
  connection: Connection,
  txid: string
) => {
  // wait for all confirmation before geting transaction
  await connection.confirmTransaction(txid, "max");

  const tx = await connection.getParsedConfirmedTransaction(txid);

  const errors: string[] = [];
  if (tx?.meta && tx.meta.logMessages) {
    tx.meta.logMessages.forEach((log) => {
      const regex = /Error: (.*)/gm;
      let m;
      while ((m = regex.exec(log)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        if (m.length > 1) {
          errors.push(m[1]);
        }
      }
    });
  }

  return errors;
};

export enum SequenceType {
  Sequential,
  Parallel,
  StopOnFailure,
}

export const sendTransactionWithRetry = async (
  connection: Connection,
  wallet: WalletSigner,
  instructions: TransactionInstruction[],
  signers: Keypair[],
  commitment: Commitment = "singleGossip",
  includesFeePayer = false,
  block?: BlockhashAndFeeCalculator,
  beforeSend?: () => void
): Promise<string | { txid: string; slot: number }> => {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  let transaction = new Transaction();
  instructions.forEach((instruction) => transaction.add(instruction));
  transaction.recentBlockhash = (
    block || (await connection.getRecentBlockhash(commitment))
  ).blockhash;

  if (includesFeePayer) {
    transaction.setSigners(...signers.map((s) => s.publicKey));
  } else {
    transaction.setSigners(
      // fee payed by the wallet owner
      wallet.publicKey,
      ...signers.map((s) => s.publicKey)
    );
  }

  if (signers.length > 0) {
    transaction.partialSign(...signers);
  }
  if (!includesFeePayer) {
    try {
      transaction = await wallet.signTransaction(transaction);
    } catch {
      return "Failed to sign transaction";
    }
  }

  if (beforeSend) {
    beforeSend();
  }
  console.log("About to send");
  try {
    const { txid, slot } = await sendSignedTransaction({
      connection,
      signedTransaction: transaction,
    });

    return { txid, slot };
  } catch (error) {
    console.error(error);
    return "See console logs";
  }
};
