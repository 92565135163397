import React from "react";
import { Spin } from "../Spin";
import { useWindowDimensions } from "../AppBar";
import { useLocalStorageState } from "../../utils";
import { StyledSeparator } from "./StylingComponents";
import { ChevronDownIcon, ChevronUpIcon } from "../Icons";
import { useDeposit } from "../../contexts/DepositContext";

const DeveloperDetails = React.lazy(() => import("../DeveloperDetails"));
export const DeveloperInfo = () => {
  const [developerDetails, setDeveloperDetails] = useLocalStorageState<boolean>(
    "mortar-developer-details-bool",
    false
  );

  return (
    <>
      <div style={{ height: 10 }} />

      <StyledSeparator />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "20px 0px",
          cursor: "pointer",
        }}
        onClick={() => setDeveloperDetails(!developerDetails)}
      >
        <div style={{ width: "100%" }}>
          <div>Are you a developer?</div>
          <div className="text-subtitle">
            Share your details and get even earlier access to a Saga
          </div>
        </div>
        <div
          style={{
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            padding: 10,
          }}
        >
          {developerDetails ? (
            <ChevronUpIcon size={20} />
          ) : (
            <ChevronDownIcon size={20} />
          )}
        </div>
      </div>

      <div style={{ height: 5 }} />

      {developerDetails && (
        <React.Suspense
          fallback={
            <div style={{ height: 100, transition: `height 400ms` }}>
              <Spin />
            </div>
          }
        >
          <DeveloperDetails />
        </React.Suspense>
      )}

      <StyledSeparator />

      <div style={{ height: 20 }} />
    </>
  );
};

export const EmailInfo = () => {
  const { emailStr, setEmailStr } = useDeposit();
  return (
    <>
      <div className="text-subtitle">Email</div>
      <form method="POST">
        <button
          type="submit"
          disabled
          style={{ display: "none" }}
          aria-hidden="true"
        ></button>
        <input
          style={{ fontSize: 16 }}
          type="email"
          name="email"
          placeholder={"Email address"}
          value={emailStr}
          onChange={(e) => setEmailStr(e.target.value?.toLowerCase)}
          autoComplete="on"
        />
      </form>
    </>
  );
};

export const CompanyInfo = () => {
  const { companyStr, setCompanyStr } = useDeposit();
  return (
    <>
      <div className="text-subtitle">Company name (optional)</div>
      <input
        style={{ fontSize: 16 }}
        placeholder={"Name of your company"}
        value={companyStr}
        onChange={(e) => setCompanyStr(e.target.value)}
      />
    </>
  );
};

export const PaymentInfo = () => {
  return (
    <>
      <div style={{ height: 20 }} />

      <div style={{ fontSize: 24, lineHeight: "30px", color: "white" }}>
        Saga deposit
      </div>
      <div
        className="text-subtitle"
        style={{ fontSize: 16, fontFamily: "soehne" }}
      >
        Saga will be available in the US, CA, EU, UK, CH, AU and NZ to start.
      </div>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <span style={{ fontSize: 80 }}>$100</span>
        &nbsp;&nbsp;
        <span style={{ fontSize: 30 }}>USDC</span>
      </div>
    </>
  );
};

export const RefundInfo = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 768 && <div style={{ height: 40 }} />}

      <div style={{ fontSize: 44, lineHeight: "44px", fontWeight: 600 }}>
        Manage existing pre
        <span style={{ fontFamily: "sans-serif" }}>&#8209;</span>order
      </div>

      <div style={{ height: 30 }} />
    </>
  );
};

export const UpdateInfo = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 768 && <div style={{ height: 40 }} />}

      <div style={{ fontSize: 44, lineHeight: "44px", fontWeight: 600 }}>
        Update developer information
      </div>

      <div style={{ height: 30 }} />
    </>
  );
};

export const OrderInfo = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 768 && <div style={{ height: 40 }} />}

      <div style={{ fontSize: 44, lineHeight: "44px", fontWeight: 600 }}>
        Credit card order information
      </div>

      <div style={{ height: 30 }} />
    </>
  );
};
