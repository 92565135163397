export const PREORDER_MAX = 10;
export const PREORDER_CONTACT = "hello@solanamobile.com";

export const isBadAmount = (preorderAmountStr: string) => {
  const preorderAmount = Number(preorderAmountStr);
  return (
    !preorderAmountStr ||
    isNaN(preorderAmount) ||
    preorderAmount < 0 ||
    Math.floor(preorderAmount) !== preorderAmount
  );
};

export const isInvalidAmount = (preorderAmountStr: string) => {
  return (
    isBadAmount(preorderAmountStr) || Number(preorderAmountStr) > PREORDER_MAX
  );
};

export function ordinal_suffix_of(i) {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

/**
 * Sends a POST request to a queryURL endpoint with data.
 *
 * @param queryURL
 * @param {any} data
 * @param contentType
 * @param accept
 * @param returnResponse
 * @param signal
 * @returns {Promise<any>}
 */
export const sendPostRequestToEndpoint = async ({
  queryURL,
  data,
  contentType = "application/json",
  accept = "application/json",
  returnResponse = false,
  signal,
}: {
  queryURL: RequestInfo | string;
  data: any;
  contentType?: string;
  accept?: string;
  returnResponse?: boolean;
  signal?: AbortSignal;
}): Promise<Response | any> => {
  try {
    const request = new Request(queryURL, {
      method: "POST",
      headers: new Headers({
        ...(accept ? { Accept: accept } : {}),
        ...(contentType ? { "Content-Type": contentType } : {}),
      }),
      body: data,
      ...(signal ? { signal } : {}),
    });
    const postResponse = await fetch(request);
    return returnResponse ? postResponse : await postResponse.json();
  } catch (err) {
    return err;
  }
};
