/**
 * Simple Twitter Conversion Provider for easy access to the twq() function.
 */

import React, { createContext, useContext, useMemo } from "react";
import {
  MORTAR_MINT_AMOUNT,
  TWITTER_ADD_TO_CART_EVENT_ID,
  TWITTER_PREORDER_PURCHASE_EVENT_ID,
} from "../utils/ids";

const TwitterConversionContext: React.Context<any> = createContext(null);

interface ITwitterConversionProps {
  children?: React.ReactNode;
}

export enum EVENT_TYPE {
  ADD_TO_CART = "ADD_TO_CART",
  ADD_TO_CART_CC = "ADD_TO_CART_CC",
  PREORDER = "PREORDER_PURCHASE",
}

export enum EVENT_CONTENT_ID {
  ADD_TO_CART = "atcusdc",
  ADD_TO_CART_CC = "atccc",
  PREORDER = "buy",
}

export const DEFAULT_CURRENCY_CODE = "USD";

export const TwitterConversionProvider: React.FC<ITwitterConversionProps> = ({
  children,
}) => {
  // @ts-ignore
  const hasTwq = typeof window !== "undefined" && !!window.twq;
  const twqFunction = useMemo(
    () =>
      hasTwq
        ? // @ts-ignore
          window.twq
        : () => {
            console.log("No TWQ found.");
          },
    [hasTwq]
  );
  return (
    <TwitterConversionContext.Provider value={twqFunction}>
      {children}
    </TwitterConversionContext.Provider>
  );
};

export const createUUID = () =>
  typeof crypto !== "undefined"
    ? crypto.randomUUID()
    : (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();

const sentConversionEventIds = new Set<string>();

export const createTwitterConversionEvent = (twq, eventType) => {
  const eventId =
    eventType === EVENT_TYPE.ADD_TO_CART ||
    eventType === EVENT_TYPE.ADD_TO_CART_CC
      ? TWITTER_ADD_TO_CART_EVENT_ID
      : TWITTER_PREORDER_PURCHASE_EVENT_ID;
  const eventName = EVENT_TYPE[eventType];
  const contentId = EVENT_CONTENT_ID[eventType];
  const generatedConversionId = createUUID();

  return ({
    conversionId,
    preorderAmount,
  }: {
    conversionId?: string;
    preorderAmount: number;
  }) => {
    const amountValue = preorderAmount * MORTAR_MINT_AMOUNT;
    const value = parseFloat(`${amountValue}`).toFixed(2);
    const currentConversionId = conversionId
      ? conversionId
      : generatedConversionId;

    if (sentConversionEventIds.has(currentConversionId)) {
      return;
    }

    twq("event", eventId, {
      value: value, // use this to pass the value of the conversion (e.g. 5.00)
      currency: DEFAULT_CURRENCY_CODE, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
      contents: [
        // use this to pass an array of products or content
        // add all items to the array
        // use this for the first item
        {
          content_type: eventName,
          content_id: contentId,
          content_name: "Saga Preorder",
          content_price: amountValue,
          num_items: preorderAmount,
          // content_group_id: null,
        },
      ],
      conversion_id: currentConversionId,
    });

    sentConversionEventIds.add(currentConversionId);
  };
};

export const useTwitterConversionEvent = (eventType: EVENT_TYPE) => {
  const twq = useContext(TwitterConversionContext);
  const sendTwitterConversionEvent = useMemo(
    () => createTwitterConversionEvent(twq, eventType),
    [twq, eventType]
  );
  return { sendTwitterConversionEvent, twq };
};
