import React from "react";
import { createQR } from "@solana/pay";

import {
  LAMBDA_PURCHASE_URL_PAYER,
  LAMBDA_REFUND_URL_PAYER,
} from "../utils/ids";

const QRCode = ({ url }: { url: string }) => {
  const qrCodeUrl = React.useMemo(
    () => `solana:${url.slice(0, url.length - 1)}`,
    [url]
  );
  const qrCode = React.useMemo(() => createQR(qrCodeUrl), [qrCodeUrl]);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  return (
    <div
      style={{
        maxWidth: 600,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div ref={ref} />
    </div>
  );
};

export const PurchaseQR = () => {
  return <QRCode url={LAMBDA_PURCHASE_URL_PAYER} />;
};

export const RefundQR = () => {
  return <QRCode url={LAMBDA_REFUND_URL_PAYER} />;
};
