import React from "react";
import { Link, useLocation } from "react-router-dom";

import * as Dialog from "@radix-ui/react-dialog";
import { useWallet } from "@solana/wallet-adapter-react";
import { CrossIcon, MenuIcon, ChevronRightIcon } from "../Icons";
import { DialogOverlay, DialogContent, DialogIconButton } from "../Dialog";
import { IconButton } from "../IconButton";

import { CurrentUserBadge, CurrentUserBadgeMobile } from "../CurrentUserBadge";
import { ConnectButton } from "../buttons/ConnectedButton";

const getDefaultLinkActions = () => {
  return [
    <NavbarButton to={`/`} key={"signup"} name={`Join the waitlist`} />,
    <NavbarButton
      to={`/manage`}
      key={"manage"}
      name={`Manage an existing pre-order`}
    />,
    <NavbarButton
      to={`/update`}
      key={"update"}
      name={`Update developer information`}
    />,
  ];
};

const NavbarButton = ({
  to,
  name,
  extra,
}: {
  to: string;
  name: string;
  extra?: React.ReactNode;
}) => {
  const location = useLocation();
  return (
    <Link
      className={`app-btn ${location.pathname === to && "current-page"}`}
      to={to}
    >
      <div
        style={{
          color: "white",
          width: "100%",
          fontSize: 18,
          height: 70,
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ width: "100%" }}>{name}</span>
        {extra}
      </div>
    </Link>
  );
};

const MobileNavbarButton = (props) => {
  return (
    <NavbarButton
      extra={
        <span>
          <ChevronRightIcon size={25} />
        </span>
      }
      {...props}
    />
  );
};

const getMobileLinkActions = () => {
  return [
    <MobileNavbarButton to={`/`} key={"signup"} name={`Join the waitlist`} />,
    <MobileNavbarButton
      to={`/manage`}
      key={"manage"}
      name={`Manage an existing pre-order`}
    />,
    <MobileNavbarButton
      to={`/update`}
      key={"update"}
      name={`Update developer information`}
    />,
  ];
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  const isMobileDevice = window.navigator.userAgent
    .toLowerCase()
    .includes("mobi");
  if (!isMobileDevice) {
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  }

  return windowDimensions;
}

export const LogoLink = () => {
  return (
    <Link to={`/`}>
      <p className={"app-logo"}>Saga</p>
    </Link>
  );
};

const DefaultActions = ({ vertical = false }: { vertical?: boolean }) => {
  return (
    <div
      className="mortar-menu-actions"
      style={{
        display: "flex",
        flexDirection: vertical ? "column" : "row",
      }}
    >
      {getDefaultLinkActions()}
    </div>
  );
};

export const MortarMenu = () => {
  const { width } = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const { connected } = useWallet();

  if (width <= 768)
    return (
      <>
        <Dialog.Root open={isModalVisible} onOpenChange={setIsModalVisible}>
          <Dialog.Portal>
            <DialogOverlay />
            <DialogContent
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div className="site-card-wrapper mobile-menu-modal">
                <ul
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginTop: 60 }}
                >
                  {getMobileLinkActions().map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
                <div className="actions">
                  {!connected ? (
                    <div className="actions-buttons">
                      <ConnectButton
                        onClick={() => setIsModalVisible(false)}
                        allowWalletChange
                      >
                        Connect Wallet
                      </ConnectButton>
                    </div>
                  ) : (
                    <>
                      <CurrentUserBadgeMobile
                        showAddress={true}
                        iconSize={24}
                        closeModal={() => {
                          setIsModalVisible(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <Dialog.Close asChild>
                <DialogIconButton
                  onClick={() => setIsModalVisible(false)}
                  style={{ padding: 10 }}
                >
                  <CrossIcon size={25} />
                </DialogIconButton>
              </Dialog.Close>
            </DialogContent>
          </Dialog.Portal>
          <Dialog.Trigger asChild>
            <IconButton
              onClick={() => setIsModalVisible(true)}
              style={{ padding: 10, marginLeft: 12, position: "relative" }}
            >
              <MenuIcon size={30} />
              {connected && (
                <div
                  style={{
                    backgroundColor: "black",
                    width: 16,
                    height: 16,
                    borderRadius: "50%",
                    position: "absolute",
                    left: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#9945FF",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </IconButton>
          </Dialog.Trigger>
        </Dialog.Root>
      </>
    );

  return <DefaultActions />;
};
export const MobileNavbar = () => {
  return (
    <div id="mobile-navbar">
      {/*<LogoLink />*/}
      <div className="mobile-menu">
        <MortarMenu />
      </div>
    </div>
  );
};

export const AppBar = () => {
  const { connected } = useWallet();
  return (
    <>
      <MobileNavbar />
      <div id="desktop-navbar" style={{ height: 56 }}>
        <div className="app-left"></div>
        <div className="app-right">
          {/*!connected && (
            <ConnectButton style={{ padding: '21px 20px' }} allowWalletChange>
              Connect Wallet
            </ConnectButton>
          )*/}
          {connected && (
            <>
              <CurrentUserBadge showAddress={true} iconSize={24} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
