import { keyframes, styled } from "@stitches/react";

export const IconButton = styled("button", {
  all: "unset",
  height: 25,
  minWidth: 25,
  maxWidth: 25,
  margin: 3,
  borderRadius: "100%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

const fade = keyframes({
  "0%": { backgroundColor: "rgba(0, 0, 0, 50%)" },
  "100%": { backgroundColor: "rgba(64, 64, 64, 90%)" },
});

export const DarkIconButton = styled(IconButton, {
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.8)",

  "&:hover": {
    backgroundColor: "rgba(64, 64, 64, 90%)",
    animation: `${fade} 100ms`,
  },
});
