import React from "react";
import { PublicKey } from "@solana/web3.js";
import { shortenAddress } from "../utils/common";

import { CopyableText } from "./CopyText";

export const MonospacedPublicKey = ({ address }: { address: PublicKey }) => {
  return (
    <span style={{ fontFamily: "monospace" }}>
      {shortenAddress(new PublicKey(address).toBase58())}
    </span>
  );
};

export const CopyableKey = ({ address }: { address: PublicKey }) => {
  return (
    <CopyableText
      display={<MonospacedPublicKey address={address} />}
      text={address.toBase58()}
    />
  );
};
